import PropTypes from "prop-types"
import React, { useState, useLayoutEffect } from "react"
import { GtmEventType, GtmAction } from "../../utils/handlers/gtmHandler"
import igoLogo from "../../assets/igo.png"

import * as S from "./style"

import LUButton from "../LUButton"
import LULogo from "../LULogo/index"

const LUNavigationBar = ({
  startBgColour,
  scrollBgColour,
  logoUrl,
  secondaryLogoUrl,
  isOntario,
  ctaText,
  ctaUrl,
  isNewMobileTemplate,
  isOrangeBgLayout
}) => {
  const [isViewportAtTop, setIsViewportAtTop] = useState(true)

  useLayoutEffect(() => {
    const handleScroll = () => setIsViewportAtTop(window.scrollY <= 0)

    window.addEventListener("scroll", handleScroll)

    handleScroll()

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const ctaGtmData = {
    eventType: GtmEventType.BUTTON_CLICK,
    action: GtmAction.STICKY_HEADER_CTA_CLICK,
    label: ctaText
  }

  const logoSize = {
    wvWidth: "auto",
    wvHeight: "45"
  }

  const shouldLVLogoShrink = !!secondaryLogoUrl || (!!secondaryLogoUrl && isOntario)

  return (
    <S.NavigationBar
      isViewportAtTop={isViewportAtTop}
      startBgColour={startBgColour}
      scrollBgColour={scrollBgColour}
      isNewMobileTemplate={isNewMobileTemplate}
      isOrangeBgLayout={isOrangeBgLayout}
    >
      <S.NavBarInner
        isNewMobileTemplate={isNewMobileTemplate}
        isLogoCentered={isOrangeBgLayout && !isOntario && !secondaryLogoUrl}
        isViewportAtTop={isViewportAtTop}
      >
        <S.FlexContainer>
          <LULogo
            url={ctaUrl}
            imageUrl={logoUrl}
            shouldResize={!isViewportAtTop}
            {...(!secondaryLogoUrl && logoSize)}
            shrink={shouldLVLogoShrink}
            isNewMobileTemplate={isNewMobileTemplate}
          />
          {secondaryLogoUrl && <LULogo isAffiliateLogo shrink imageUrl={secondaryLogoUrl} isNewMobileTemplate={isNewMobileTemplate} />}
        </S.FlexContainer>
        <S.FlexContainer>
          {isOntario && <S.IGOLogo alt="iGaming Ontario Logo" src={igoLogo} />}
          {ctaText && ctaUrl && !isOrangeBgLayout && (
            <LUButton url={ctaUrl} fontSize="1.3rem" height="32px" gtmData={ctaGtmData} isHeaderCta>
              {ctaText}
            </LUButton>
          )}
        </S.FlexContainer>
      </S.NavBarInner>
    </S.NavigationBar>
  )
}

LUNavigationBar.propTypes = {
  startBgColour: PropTypes.string,
  scrollBgColour: PropTypes.string,
  logoUrl: PropTypes.string.isRequired,
  secondaryLogoUrl: PropTypes.string,
  isOntario: PropTypes.bool,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  isNewMobileTemplate: PropTypes.bool,
  isOrangeBgLayout: PropTypes.bool
}

LUNavigationBar.defaultProps = {
  startBgColour: "transparent",
  scrollBgColour: "#f3f3f3",
  secondaryLogoUrl: null,
  isOntario: false,
  ctaText: null,
  ctaUrl: null,
  isNewMobileTemplate: false,
  isOrangeBgLayout: false
}

export default LUNavigationBar
