import styled, { css } from "styled-components"
import { Res } from "../../utils/constants"

export const Logo = styled.a`
  position: relative;

  z-index: 3;

  overflow: hidden;

  width: 200px;

  ${props =>
    props.isNewMobileTemplate &&
    css`
      height: 46px;
      margin: 5px 0;
    `}

  ${props =>
    props.shouldResize &&
    css`
      width: 44px;
      height: 44px;

      ${props.isNewMobileTemplate &&
      css`
        width: 55px !important;
        height: 55px;
        position: absolute;
        top: 0;
        img {
          width: auto !important;
          max-width: unset !important;
          height: 100% !important;
        }
      `}

      img {
        width: auto;
        height: 100%;
      }
    `};

  background: ${props => props.$primaryLogoBgColour};

  transition: all 0.2s ease-in-out;

  ${props =>
    props.isAffiliateLogo &&
    css`
      display: flex;
      align-items: center;

      width: 145px;
      max-height: 44px;
      img {
        max-height: 44px;
      }
      ${!props.isNewMobileTemplate &&
      css`
        @media (min-width: ${Res._768}px) {
          width: 185px;
          max-height: 50px;
          img {
            max-height: 50px;
          }
        }
      `}
    `}

  @media (max-width: ${Res._769}px) {
    ${props =>
      props.isAffiliateLogo &&
      props.shrink &&
      css`
        width: ${props.isNewMobileTemplate ? `115px` : `85px`};
      `}
    }

    ${props =>
      props.shrink &&
      !props.shouldResize &&
      !props.isAffiliateLogo &&
      css`
        ${props.isNewMobileTemplate
          ? css`
              width: 55px !important;
              height: 55px;
              position: absolute;
              top: 0;
              img {
                width: auto !important;
                max-width: unset !important;
                height: 100% !important;
              }
            `
          : css`
              @media (max-width: ${Res._512}px) {
                width: 120px;
              }
            `}

        img {
          height: auto;
        }
      `}
  }

  @media (max-width: 360px) {
    ${props =>
      props.isAffiliateLogo &&
      props.shrink &&
      !props.isNewMobileTemplate &&
      css`
        width: 65px;
      `}
  }

  img {
    ${props =>
      props.shrink &&
      !props.shouldResize &&
      css`
        width: auto;
        max-width: ${props.shouldResize ? "auto" : "100%"};
      `}
  }

  :nth-child(2) {
    margin-left: 12px;

    ${props =>
      props.isNewMobileTemplate &&
      css`
        margin-left: 67px;
      `}
  }
`
