/* eslint-disable react/no-array-index-key */
import React, { useEffect, createContext } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { navigate } from "gatsby"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "../../styles/globalStyle"
import { transformDomain } from "../../helpers/index"
import { createRedirects } from "../../utils/RedirectsManager"
import { useFetchGeo } from "../../utils/hooks"
import { pushCountryToDataLayer, useCookieConsent } from "../../utils/handlers/gtmHandler"

const HtmlLanguageCodeDict = {
  dk: "da" // Denmark - Danish
}

export const Context = createContext()

const getGtmIds = language => {
  switch (language) {
    case "gb":
    case "nl":
      return "GTM-NZJ7QSJ"
    default:
      return process.env.GATSBY_GTM_ID
  }
}

const Layout = ({ location, title, language, children, backgroundColour, theme, isOrangeBgLayout }) => {
  const subdivision = useFetchGeo()
  const data = createRedirects(location, subdivision)

  const { showConsent, shouldLoadGtm, accept } = useCookieConsent()

  const gtmId = getGtmIds(language)

  const domainExtension = transformDomain(language)

  const gtmDefaultScript = `
  {window.dataLayer = window.dataLayer || [];
    function gtag() {dataLayer.push(arguments);}
    if (localStorage.getItem('consentMode') === null){
      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'personalization_storage': 'denied',
        'functionality_storage': 'denied',
        'security_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied'
      })
    } else {
      gtag ('consent', 'default', JSON.parse(localStorage.getItem('consentMode')))
    }}`

  useEffect(() => {
    const { pathname } = window.location

    const script = document.createElement("script")
    script.innerHTML = gtmDefaultScript

    const head = document.querySelector("head")
    head.appendChild(script)

    pushCountryToDataLayer(pathname, title)
  }, [])

  if ((!data.isSubdivPageAvailable && data.isSubdivisionListed) || data.caSlugIncorrect) {
    navigate(data.redirectUrl)
  } else {
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="Description" content="A LeoVegas promotional page." />
          <html lang={HtmlLanguageCodeDict[language] || language} />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="robots" content="noindex, nofollow" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
          <meta name="theme-color" content="#E75F3F" />
          <meta name="format-detection" content="telephone=no" />
          <link rel="preconnect" href="https://sslwidget.criteo.com" />
          <link rel="preconnect" href="https://static.criteo.net" />
          <link rel="preconnect" href="https://script.hotjar.com" />
          <link rel="preconnect" href="https://vars.hotjar.com" />
          <link rel="preconnect" href="https://www.google-analytics.com" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap"
            rel="stylesheet"
          />
          {shouldLoadGtm &&
            gtmId?.split(",").map((id, i) => (
              <script key={i}>
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://sgtm.leovegas.${domainExtension}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${id}');`}
              </script>
            ))}
          {shouldLoadGtm &&
            gtmId?.split(",").map((id, i) => (
              <noscript
                key={i}
                dangerouslySetInnerHTML={{
                  __html: `<iframe src="https://sgtm.leovegas.${domainExtension}/ns.html?id=${id}" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>
          `
                }}
              />
            ))}
        </Helmet>
        <ThemeProvider theme={theme}>
          <GlobalStyle backgroundColour={backgroundColour} isOrangeBgLayout={isOrangeBgLayout} />
          <Context.Provider value={{ cookieConsent: { showConsent, accept } }}>{children}</Context.Provider>
        </ThemeProvider>
      </>
    )
  }
  return null
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  backgroundColour: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  theme: PropTypes.objectOf(PropTypes.any).isRequired,
  isOrangeBgLayout: PropTypes.bool
}

Layout.defaultProps = {
  backgroundColour: "#fff",
  isOrangeBgLayout: false
}

export default Layout
